<template>
  <div class="contentCnfigP">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="title">Gestion des paies</div>
        <div>
          <b-button
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Export</b-button
          >
          <b-button
            variant="success"
            class="config-btn ml-2"
            v-b-modal.ConfigurationPaie
            ><font-awesome-icon icon="plus" /> Nouvelle configuration</b-button
          >
        </div>

        <b-modal
          id="ConfigurationPaie"
          ref="ConfigurationPaie"
          title="Nouvelle configuration"
          no-close-on-backdrop
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap custom-config-modal-size"
        >
          <div class="hader mb-3">
            <div class="titleSetting">Nouvelle configuration</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('ConfigurationPaie')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="ConfigurationPaies"
                class="form-modal-custom-style"
              >
                <b-form-radio-group
                  v-model="ConfigType"
                  :options="options"
                  name="plain-inline"
                  class="mb-4"
                  plain
                ></b-form-radio-group>

                <b-form-group
                  label="Année"
                  label-for="year"
                  class=" input-modal-champ "
                >
                  <b-form-select
                    id="year"
                    v-model="paie.year"
                    :options="ListYear"
                    required
                    @change="handleChangeYearModel"
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Semaine"
                  label-for="semaine"
                  class="input-modal-champ "
                >
                  <b-form-select
                    id="semaine"
                    v-model="paie.semaine"
                    :options="ListWeek"
                    @change="handleChangeSemaineModel"
                    required
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Type"
                  label-for="typeUser"
                  class="input-modal-champ"
                >
                  <b-form-select
                    id="typeUser"
                    v-model="paie.type"
                    :options="ComputedgetType"
                    value-field="value"
                    text-field="text"
                    required
                    @change="handleTypeChange"
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Utilisateur"
                  label-for="User"
                  class="vue-select-modal-champ"
                  v-if="ConfigType == 'user' && paie.type != null"
                >
                  <multiselect
                    v-model="paie.employee.id"
                    track-by="full_name"
                    placeholder="Rechercher"
                    label="full_name"
                    :options="getAllEmployeeByType"
                    :searchable="true"
                    :loading="isLoading"
                    required
                    class="select-vue-component-style"
                    ref="userSelect"
                    :showLabels="false"
                  >
                    <span slot="noResult">Aucun utilisateur trouvé </span>
                    <span slot="noOptions">La liste est vide</span>
                  </multiselect>
                  <div class="messageError">
                    <span v-if="getErronInFetchActiveUser" class="error">{{
                      this.getErronInFetchActiveUser
                    }}</span>
                    <span v-if="showErrorUser == true" class="error">
                      Sélectionnez un utilisateur</span
                    >
                  </div>
                </b-form-group>
                <b-form-radio-group
                  v-model="paie.facture_type"
                  :options="optionsFactureType"
                  class="mt-2"
                  plain
                ></b-form-radio-group>
                <div
                  class="ModelCol checkbox-style-type"
                  v-if="paie.type != null"
                >
                  <b-form-checkbox
                    v-model="thType"
                    name="check-button"
                    switch
                    class="check-th"
                    :class="{ 'check-th-active': thType == true }"
                  >
                    <b>Bar TH</b>
                  </b-form-checkbox>
                  <div
                    class="ModelCol condition-th-style "
                    v-if="thType == true"
                  >
                    <b-form-radio-group
                      v-model="paie.TH_condition"
                      :options="computedOptionsTh"
                      plain
                      stacked
                    ></b-form-radio-group>
                  </div>
                </div>
                <div>
                  <div v-if="thType == true" class="bar-th-style ">
                    <div class="champ-bar-th">
                      <b-form-group
                        v-if="
                          computedThRest(paie) || computedThPoseurSalarie(paie)
                        "
                        label=""
                        label-for="TH_VALUE"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_VALUE"
                          v-model="paie.TH_value"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <div class="ModelCol">
                        <b-form-group
                          v-if="computedThPrevisiteur(paie)"
                          label="Vente"
                          label-for="th-value-vente"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="th-value-vente"
                            v-model="paie.TH_vente"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="computedThPrevisiteur(paie)"
                          label="Previsite"
                          label-for="th-value-previsite"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="th-value-previsite"
                            v-model="paie.TH_previsite"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="ModelCol">
                        <b-form-group
                          v-if="computedThSedentaire(paie)"
                          label="Externe"
                          label-for="th-value-externe"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="th-value-externe"
                            v-model="paie.TH_externe"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="computedThSedentaire(paie)"
                          label="Interne"
                          label-for="th-value-interne"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="th-value-interne"
                            v-model="paie.TH_interne"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>

                      <template v-if="computedThPoseurIndependant(paie)">
                        <div class="ModelCol">
                          <b-form-group
                            label="ITE"
                            label-for="TH_ITE"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_ITE"
                              v-model="paie.TH_ITE"
                              required
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="ITI"
                            label-for="TH_ITI"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_ITI"
                              v-model="paie.TH_ITI"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="ModelCol">
                          <b-form-group
                            label="PAC RO"
                            label-for="TH_PAC_RO"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_PAC_RO"
                              v-model="paie.TH_PAC_RO"
                              required
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="PAC Balon thermo"
                            label-for="TH_PAC_Balon_thermo"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              id="TH_PAC_Balon_thermo"
                              v-model="paie.TH_PAC_Balon_thermo"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>

                        <b-form-group
                          label="RR"
                          label-for="TH_RR"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="TH_RR"
                            v-model="paie.TH_RR"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </template>
                      <div class="ligne-th"></div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="ModelCol checkbox-style-type"
                      v-if="paie.type != null"
                    >
                      <b-form-checkbox
                        v-model="isoType"
                        name="check-button"
                        switch
                        class="check-th"
                        disabled
                      >
                        <b>Iso à 1 € </b>
                      </b-form-checkbox>
                    </div>

                    <div class="ModelCol" v-if="computedSedentaire(paie)">
                      <div class="align-left-div">
                        <span class="label-gestion-paie"
                          >Dossier MPR (Oui)</span
                        >
                        <b-form-group
                          label="Externe"
                          label-for="externe_mpr_oui"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="externe_mpr_oui"
                            v-model="paie.externe_mpr_oui"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="Interne"
                          label-for="interne_mpr_oui"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="interne_mpr_oui"
                            v-model="paie.interne_mpr_oui"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="align-left-div ">
                        <span class="label-gestion-paie"
                          >Dossier MPR (Non)</span
                        >
                        <b-form-group
                          label="Externe"
                          label-for="externe_mpr_non"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="externe_mpr_non"
                            v-model="paie.externe_mpr_non"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="Interne"
                          label-for="interne_mpr_non"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="interne_mpr_non"
                            v-model="paie.interne_mpr_non"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="ModelCol" v-if="computedPrevisiteur(paie)">
                      <div class="align-left-div">
                        <span class="label-gestion-paie"
                          >Dossier MPR (Oui)</span
                        >
                        <b-form-group
                          label="Vente"
                          label-for="vente_mpr_oui"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="vente_mpr_oui"
                            v-model="paie.vente_mpr_oui"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="Prévisite"
                          label-for="previsite_mpr_oui"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="previsite_mpr_oui"
                            v-model="paie.previsite_mpr_oui"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="align-left-div ">
                        <span class="label-gestion-paie"
                          >Dossier MPR (Non)</span
                        >
                        <b-form-group
                          label="Vente"
                          label-for="vente_mpr_non"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="vente_mpr_non"
                            v-model="paie.vente_mpr_non"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="Prévisite"
                          label-for="interne_mpr_non"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="previsite_mpr_non"
                            v-model="paie.previsite_mpr_non"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                    <div v-if="computedPoseurCoposeur(paie)">
                      <div class="align-left-div">
                        <span class="label-gestion-paie"
                          >Dossier MPR (Oui)</span
                        >
                      </div>
                      <div class="ModelCol">
                        <b-form-group
                          label="Surface iti"
                          label-for="surface_iti_mpr_oui"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="surface_iti_mpr_oui"
                            v-model="paie.surface_iti_mpr_oui"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="Surface Rampant"
                          label-for="surface_mpr_oui"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="surface_mpr_oui"
                            v-model="paie.surface_mpr_oui"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <!-- <div class="align-left-div ">
                        <span class="label-gestion-paie"
                          >Dossier MPR (Non)</span
                        >
                        <b-form-group
                          label="Surface iti"
                          label-for="surface_iti_mpr_non"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="surface_iti_mpr_non"
                            v-model="paie.surface_iti_mpr_non"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div> -->
                    </div>
                    <br />
                    <div class="ModelCol" v-if="computedAutre(paie)">
                      <b-form-group
                        label="Variable 101"
                        label-for="variable_101"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="variable_101"
                          v-model="paie.variable_101"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Variable 102 Standard"
                        label-for="variable_102_Standard"
                        class="  input-modal-champ"
                      >
                        <b-form-input
                          id="variable_102_Standard"
                          v-model="paie.variable_102_Standard"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol" v-if="computedAutre(paie)">
                      <b-form-group
                        label="Variable 102 ITI + "
                        label-for="variable_102_ITI"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="variable_102_ITI"
                          v-model="paie.variable_102_ITI"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Variable 102 ITI + Placo"
                        label-for="variable_102_ITI_placo"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="variable_102_ITI_placo"
                          v-model="paie.variable_102_ITI_placo"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol">
                      <b-form-group
                        v-if="computedAutre(paie)"
                        label="Variable 103"
                        label-for="variable_103"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="variable_103"
                          v-model="paie.variable_103"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="
                          paie.type != null &&
                            paie.type != 'Admin GE' &&
                            paie.type != 'Responsable planning'
                        "
                        label="Variable Vmc"
                        label-for="variable_vmc"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="variable_vmc"
                          v-model="paie.variable_vmc"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div
                      class="ModelCol"
                      v-if="
                        paie.type != null &&
                          paie.type != 'Admin GE' &&
                          paie.type != 'Responsable planning'
                      "
                    >
                      <b-form-group
                        label="Variable PAC"
                        label-for="variable_pac"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="variable_pac"
                          v-model="paie.variable_pac"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Indemnité Grand deplacement  "
                        label-for="IGD"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="IGD"
                          v-model="paie.IGD"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div
                      class="ModelCol"
                      v-if="
                        paie.type != null &&
                          paie.type != 'Admin GE' &&
                          paie.type != 'Responsable planning'
                      "
                    >
                      <b-form-group
                        label="Prime Qualité"
                        label-for="prime_qualite"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="prime_qualite"
                          v-model="paie.prime_qualite"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Prime Objectif "
                        label-for="prime_objectif"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="prime_objectif"
                          v-model="paie.prime_objectif"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div
                      class="ModelCol"
                      v-if="
                        paie.type != null &&
                          paie.type != 'Admin GE' &&
                          paie.type != 'Responsable planning'
                      "
                    >
                      <b-form-group
                        label="Prime Sav"
                        label-for="prime_sav"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="prime_sav"
                          v-model="paie.prime_sav"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Prime exceptionnelle"
                        label-for="prime-e"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="prime-e"
                          v-model="paie.additional_prime"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div
                      class="ModelCol"
                      v-if="
                        paie.type != null &&
                          paie.type != 'Admin GE' &&
                          paie.type != 'Responsable planning'
                      "
                    >
                      <b-form-group
                        label="Fix"
                        label-for="prime-m"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="prime-m"
                          v-model="paie.fix"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Deducation"
                        label-for="deducation"
                        class=" input-modal-champ"
                      >
                        <b-form-input
                          id="deducation"
                          v-model="paie.deducation"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div
                      class="ModelCol"
                      v-if="
                        paie.type != null &&
                          paie.type != 'Admin GE' &&
                          paie.type != 'Responsable planning'
                      "
                    >
                      <b-form-group
                        label="Autre Prime (Nom)"
                        label-for="other_prime_name"
                        class="mr-2  input-modal-champ"
                      >
                        <b-form-input
                          id="other_prime_name"
                          v-model="paie.other_prime_name"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Autre Prime (Montant)"
                        label-for="other_prime_value"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="other_prime_value"
                          v-model="paie.other_prime_value"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div
                      class="ModelCol-min mb-2 mr-3"
                      v-if="
                        ConfigType == 'user' &&
                          (paie.type === 'Admin GE' ||
                            paie.type === 'Responsable planning')
                      "
                    >
                      <label id="label_source">Variable source</label>
                      <b-form-input
                        class="mr-2  input-modal-champ"
                        id="variable_source"
                        v-model="paie.variable_source"
                      ></b-form-input>
                      <multiselect
                        v-model="paie.source"
                        placeholder="list source"
                        track-by="name"
                        label="name"
                        :options="getSources"
                        :searchable="true"
                        required
                        :multiple="true"
                        :close-on-select="false"
                        class="select-vue-component-style "
                        :showLabels="false"
                      >
                        <span slot="noResult">Aucun source trouvé </span>
                        <span slot="noOptions">La liste est vide</span>
                      </multiselect>
                    </div>
                    <div
                      class="ModelCol-min mb-2 mr-3"
                      v-if="
                        ConfigType == 'user' &&
                          (paie.type === 'Admin GE' ||
                            paie.type === 'Responsable planning')
                      "
                    >
                      <label id="label_depot">Variable dépôts</label>
                      <b-form-input
                        class="mr-2  input-modal-champ"
                        id="variable_depot"
                        v-model="paie.variable_depot"
                      ></b-form-input>

                      <multiselect
                        v-model="paie.depot"
                        placeholder="list depot"
                        track-by="name"
                        label="name"
                        :options="getDepots"
                        :searchable="true"
                        required
                        :multiple="true"
                        :close-on-select="false"
                        class="select-vue-component-style "
                        :showLabels="false"
                      >
                        <span slot="noResult">Aucun depot trouvé </span>
                        <span slot="noOptions">La liste est vide</span>
                      </multiselect>
                    </div>
                  </div>
                </div>

                <div class="actionModel">
                  <div class="messageError mt-3">
                    <div v-if="getErrorUserPaies" class="error">
                      {{ getErrorUserPaies }}
                    </div>
                  </div>

                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="getloagingUserPaies" class="loading ml-2">
                        <div class="spinner-border" role="status"></div></div
                    ></span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
      <div class="ligne-box-setting-header"></div>
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChange"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel ">Type</span>
          </div>

          <b-form-select
            class="b-form-select-new-style mr-2"
            v-model="filterType"
            :options="[{ text: 'tous', value: null }, ...ComputedgetType]"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Utilisateur</span>
          </div>

          <SelectComponent
            :options="ComputedgetAllEmployee"
            :value="filterUser"
            label="full_name"
            champName="utilisateur"
            filterName="filterUser"
            :change="handleChange"
            :track-by="'full_name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style w-193-px"
          />
        </div>
        <div v-if="getChargementPaie" class="chargement ml-1">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div>
      <b-table
        show-empty
        id="my-table"
        class="custom-table-style table-gestion-paies-style"
        :items="computedgetuserConfig"
        :fields="computedFiled"
        bordered
        sticky-header
        hover
        head-variant="light"
        tbody-tr-class="ligneNormale"
        empty-text="Il n'y a aucun enregistrement à afficher"
      >
        <template v-slot:cell(Actions)="data">
          <b-button
            class="button-succes-style mr-2"
            size="sm"
            variant="success"
            @click.prevent.stop="handleUpdate(data.item)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </b-button>
        </template>
        <template v-slot:cell(name)="data">
          {{ data.item.employee.full_name }}
        </template>
        <template v-slot:cell(mail)="data">
          {{ data.item.employee.email }}
        </template>
        <template v-slot:cell(type)="data">
          {{ data.item.type }} ({{ data.item.facture_type }})
        </template>
        <template v-slot:cell(equipe)="data">
          {{ data.item.employee.team }}
        </template>
        <template v-slot:cell(TH_vente)="data">
          {{ data.item.TH_vente }}
        </template>
        <template v-slot:cell(TH_previsite)="data">
          {{ data.item.TH_previsite }}
        </template>
        <template v-slot:cell(depot_employee)="data">
          {{ data.item.employee.depot }}
        </template>
        <template v-slot:cell(semaine)="data">
          {{ data.item.semaine }}
        </template>
        <template v-slot:cell(variable_101)="data">
          {{ data.item.variable_101 }}
        </template>
        <template v-slot:cell(variable_102_ITI)="data">
          {{ data.item.variable_102_ITI }}
        </template>
        <template v-slot:cell(variable_102_ITI_placo)="data">
          {{ data.item.variable_102_ITI_placo }}
        </template>
        <template v-slot:cell(variable_102_Standard)="data">
          {{ data.item.variable_102_Standard }}
        </template>
        <template v-slot:cell(variable_103)="data">
          {{ data.item.variable_103 }}
        </template>
        <template v-slot:cell(variable_vmc)="data">
          {{ data.item.variable_vmc }}
        </template>
        <template v-slot:cell(variable_pac)="data">
          {{ data.item.variable_pac }}
        </template>
        <template v-slot:cell(variable_source)="data">
          {{ data.item.variable_source }}
        </template>
        <template v-slot:cell(source)="data">
          <div class="List">
            <span
              v-for="itemSource in data.item.source"
              :key="itemSource.index"
            >
              -{{ itemSource.name }}
            </span>
          </div>
        </template>
        <template v-slot:cell(variable_depot)="data">
          {{ data.item.variable_depot }}
        </template>
        <template v-slot:cell(depot)="data">
          <div class="List">
            <span v-for="itemDepot in data.item.depot" :key="itemDepot.index">
              -{{ itemDepot.name }}
            </span>
          </div>
        </template>
        <template v-slot:cell(IGD)="data">
          {{ data.item.IGD }}
        </template>
        <template v-slot:cell(primeE)="data">
          {{ data.item.additional_prime }}
        </template>
        <template v-slot:cell(fix)="data">
          {{ data.item.fix }}
        </template>
        <template v-slot:cell(prime_qualite)="data">
          {{ data.item.prime_qualite }}
        </template>
        <template v-slot:cell(prime_objectif)="data">
          {{ data.item.prime_objectif }}
        </template>
        <template v-slot:cell(prime_sav)="data">
          {{ data.item.prime_sav }}
        </template>
        <template v-slot:cell(other_prime_name)="data">
          {{ data.item.other_prime_name }}
        </template>
        <template v-slot:cell(other_prime_value)="data">
          {{ data.item.other_prime_value }}
        </template>
        <template v-slot:cell(deducation)="data">
          {{ data.item.deducation }}
        </template>
        <template v-slot:cell(TH_condition)="data">
          {{ data.item.TH_condition }}
        </template>
        <template v-slot:cell(TH_value)="data">
          {{ data.item.TH_value }}
        </template>
        <template v-slot:cell(TH_externe)="data">
          {{ data.item.TH_externe }}
        </template>
        <template v-slot:cell(TH_interne)="data">
          {{ data.item.TH_interne }}
        </template>
        <template v-slot:cell(TH_RR)="data">
          {{ data.item.TH_RR }}
        </template>
        <template v-slot:cell(TH_PAC_Balon_thermo)="data">
          {{ data.item.TH_PAC_Balon_thermo }}
        </template>
        <template v-slot:cell(TH_PAC_RO)="data">
          {{ data.item.TH_PAC_RO }}
        </template>
        <template v-slot:cell(TH_ITI)="data">
          {{ data.item.TH_ITI }}
        </template>
        <template v-slot:cell(TH_ITE)="data">
          {{ data.item.TH_ITE }}
        </template>
      </b-table>
      <div class="footer-style mt-2">
        <b-pagination
          v-model="page"
          :total-rows="getTotalRowUser"
          :per-page="perPage"
          aria-controls="my-table"
          pills
          align="center"
          size="sm"
          @change="pagination"
          class="pagination-style"
        ></b-pagination>
        <div class="per-page-element-style">
          <div class="box-label-champ">
            <div class="label-box-style">
              <span class="title-tabel">Eléments par page</span>
            </div>
          </div>
          <b-form-select
            v-model="perPage"
            :options="perPageList"
            @change="changePerPage"
            class="b-form-select-new-style bg-select"
          ></b-form-select>
        </div>
      </div>

      <b-modal
        id="ModelConfigUser"
        ref="ModelConfigUser"
        title="Configuration"
        no-close-on-backdrop
        :hide-footer="true"
        :hide-header="true"
        @hidden="resetModal"
        modal-class="cutsom-modal-bootstrap"
      >
        <div class="hader mb-3">
          <div class="titleSetting">Configuration</div>
          <div class="iconClose" @click.prevent="hideModal('ModelConfigUser')">
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <Card>
          <template v-slot:body>
            <form
              @submit.prevent="ConfigurationUserNew"
              v-if="UserToConfig"
              class="form-modal-custom-style"
            >
              <div class="ModelCol">
                <b-form-group
                  label="Utilisateur"
                  label-for="Utilisateur"
                  class="input-modal-champ"
                >
                  <b-form-input
                    id="Utilisateur"
                    v-model="UserToConfig.employee.full_name"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Type"
                  label-for="typeUser"
                  class="input-modal-champ"
                >
                  <b-form-input
                    id="typeUser"
                    v-model="UserToConfig.type"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group label="Semaine" label-for="semaine" class="mr-4">
                  <b-form-select
                    id="semaine"
                    v-model="UserToConfig.semaine"
                    :options="ListWeek"
                    :disabled="true"
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <b-form-group label="Année" label-for="year">
                  <b-form-select
                    id="year"
                    v-model="filteryear"
                    :options="ListYear"
                    :disabled="true"
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
              </div>
              <b-form-radio-group
                v-model="UserToConfig.facture_type"
                :options="optionsFactureType"
                class="mt-2"
                plain
              ></b-form-radio-group>
              <div
                class="ModelCol checkbox-style-type"
                v-if="UserToConfig.type != null"
              >
                <b-form-checkbox
                  v-model="th_type_to_config"
                  name="check-button"
                  switch
                  class="check-th"
                  @change="toggleSwitchTh"
                  :class="{ 'check-th-active': th_type_to_config == true }"
                >
                  <b>Bar TH</b>
                </b-form-checkbox>
                <div
                  class="ModelCol condition-th-style "
                  v-if="th_type_to_config == true"
                >
                  <b-form-radio-group
                    v-model="UserToConfig.TH_condition"
                    :options="computedOptionsTh"
                    plain
                    stacked
                  ></b-form-radio-group>
                </div>
              </div>
              <div>
                <div v-if="th_type_to_config == true" class="bar-th-style ">
                  <div class="champ-bar-th">
                    <b-form-group
                      v-if="
                        computedThRest(UserToConfig) ||
                          computedThPoseurSalarie(UserToConfig)
                      "
                      label=""
                      label-for="TH_VALUE"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="TH_VALUE"
                        v-model="UserToConfig.TH_value"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <div class="ModelCol">
                      <b-form-group
                        v-if="computedThPrevisiteur(UserToConfig)"
                        label="Vente"
                        label-for="th-value-vente"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="th-value-vente"
                          v-model="UserToConfig.TH_vente"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="computedThPrevisiteur(UserToConfig)"
                        label="Previsite"
                        label-for="th-value-previsite"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="th-value-previsite"
                          v-model="UserToConfig.TH_previsite"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="ModelCol">
                      <b-form-group
                        v-if="computedThSedentaire(UserToConfig)"
                        label="Externe"
                        label-for="th-value-externe"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="th-value-externe"
                          v-model="UserToConfig.TH_externe"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="computedThSedentaire(UserToConfig)"
                        label="Interne"
                        label-for="th-value-interne"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="th-value-interne"
                          v-model="UserToConfig.TH_interne"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <template v-if="computedThPoseurIndependant(UserToConfig)">
                      <div class="ModelCol">
                        <b-form-group
                          label="ITE"
                          label-for="TH_ITE"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="TH_ITE"
                            v-model="UserToConfig.TH_ITE"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="ITI"
                          label-for="TH_ITI"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="TH_ITI"
                            v-model="UserToConfig.TH_ITI"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="ModelCol">
                        <b-form-group
                          label="PAC RO"
                          label-for="TH_PAC_RO"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="TH_PAC_RO"
                            v-model="UserToConfig.TH_PAC_RO"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          label="PAC Balon thermo"
                          label-for="TH_PAC_Balon_thermo"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            id="TH_PAC_Balon_thermo"
                            v-model="UserToConfig.TH_PAC_Balon_thermo"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>

                      <b-form-group
                        label="RR"
                        label-for="TH_RR"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          id="TH_RR"
                          v-model="UserToConfig.TH_RR"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </template>
                    <div class="ligne-th"></div>
                  </div>
                </div>
                <div class="ModelCol checkbox-style-type">
                  <b-form-checkbox
                    v-model="isoType"
                    name="check-button"
                    switch
                    class="check-th"
                    disabled
                  >
                    <b>Iso à 1 € </b>
                  </b-form-checkbox>
                </div>
                <div class="ModelCol" v-if="computedSedentaire(UserToConfig)">
                  <div class="align-left-div">
                    <span class="label-gestion-paie">Dossier MPR (Oui)</span>
                    <b-form-group
                      label="Externe"
                      label-for="externe_mpr_oui"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="externe_mpr_oui"
                        v-model="UserToConfig.externe_mpr_oui"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Interne"
                      label-for="interne_mpr_oui"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="interne_mpr_oui"
                        v-model="UserToConfig.interne_mpr_oui"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="align-left-div ">
                    <span class="label-gestion-paie">Dossier MPR (Non)</span>
                    <b-form-group
                      label="Externe"
                      label-for="externe_mpr_non"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="externe_mpr_non"
                        v-model="UserToConfig.externe_mpr_non"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Interne"
                      label-for="interne_mpr_non"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="interne_mpr_non"
                        v-model="UserToConfig.interne_mpr_non"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="ModelCol" v-if="computedPrevisiteur(UserToConfig)">
                  <div class="align-left-div">
                    <span class="label-gestion-paie">Dossier MPR (Oui)</span>
                    <b-form-group
                      label="Vente"
                      label-for="vente_mpr_oui"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="vente_mpr_oui"
                        v-model="UserToConfig.vente_mpr_oui"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Prévisite"
                      label-for="previsite_mpr_oui"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="previsite_mpr_oui"
                        v-model="UserToConfig.previsite_mpr_oui"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="align-left-div ">
                    <span class="label-gestion-paie">Dossier MPR (Non)</span>
                    <b-form-group
                      label="Vente"
                      label-for="vente_mpr_non"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="vente_mpr_non"
                        v-model="UserToConfig.vente_mpr_non"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Prévisite"
                      label-for="interne_mpr_non"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="previsite_mpr_non"
                        v-model="UserToConfig.previsite_mpr_non"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div v-if="computedPoseurCoposeur(UserToConfig)">
                  <div class="align-left-div">
                    <span class="label-gestion-paie">Dossier MPR (Oui)</span>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="Surface iti"
                      label-for="surface_iti_mpr_oui"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="surface_iti_mpr_oui"
                        v-model="UserToConfig.surface_iti_mpr_oui"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Surface Rampant"
                      label-for="surface_mpr_oui"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="surface_mpr_oui"
                        v-model="UserToConfig.surface_mpr_oui"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <!-- <div class="align-left-div ">
                    <span class="label-gestion-paie">Dossier MPR (Non)</span>
                    <b-form-group
                      label="Surface iti"
                      label-for="surface_iti_mpr_non"
                      class="input-modal-champ"
                    >
                      <b-form-input
                        id="surface_iti_mpr_non"
                        v-model="UserToConfig.surface_iti_mpr_non"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div> -->
                </div>
                <br />
              </div>
              <div class="ModelCol " v-if="computedAutre(UserToConfig)">
                <b-form-group
                  label="Variable 101"
                  label-for="variable_101"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="variable_101"
                    v-model="UserToConfig.variable_101"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Variable 102 Standard"
                  label-for="variable_102_Standard"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="variable_102_Standard"
                    v-model="UserToConfig.variable_102_Standard"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol" v-if="computedAutre(UserToConfig)">
                <b-form-group
                  label="Variable 102 ITI + "
                  label-for="variable_102_ITI"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="variable_102_ITI"
                    v-model="UserToConfig.variable_102_ITI"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Variable 102 ITI + Placo"
                  label-for="variable_102_ITI_placo"
                  class="input-modal-champ"
                >
                  <b-form-input
                    id="variable_102_ITI_placo"
                    v-model="UserToConfig.variable_102_ITI_placo"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol">
                <b-form-group
                  v-if="computedAutre(UserToConfig)"
                  label="Variable 103"
                  label-for="variable_103"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="variable_103"
                    v-model="UserToConfig.variable_103"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="
                    UserToConfig.type != 'Admin GE' &&
                      UserToConfig.type != 'Responsable planning'
                  "
                  label="Variable Vmc"
                  label-for="variable_vmc"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="variable_vmc"
                    v-model="UserToConfig.variable_vmc"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="ModelCol"
                v-if="
                  UserToConfig.type != 'Admin GE' &&
                    UserToConfig.type != 'Responsable planning'
                "
              >
                <b-form-group
                  label="Variable PAC"
                  label-for="variable_pac"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="variable_pac"
                    v-model="UserToConfig.variable_pac"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Indemnité Grand deplacement"
                  label-for="IGD"
                  class="input-modal-champ"
                >
                  <b-form-input
                    id="IGD"
                    v-model="UserToConfig.IGD"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="ModelCol"
                v-if="
                  UserToConfig.type != 'Admin GE' &&
                    UserToConfig.type != 'Responsable planning'
                "
              >
                <b-form-group
                  label="Prime Qualité"
                  label-for="prime_qualite"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="prime_qualite"
                    v-model="UserToConfig.prime_qualite"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Prime Objectif "
                  label-for="prime_objectif"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="prime_objectif"
                    v-model="UserToConfig.prime_objectif"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div
                class="ModelCol"
                v-if="
                  UserToConfig.type != 'Admin GE' &&
                    UserToConfig.type != 'Responsable planning'
                "
              >
                <b-form-group
                  label="Prime Sav"
                  label-for="prime_sav"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="prime_sav"
                    v-model="UserToConfig.prime_sav"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Prime exceptionnelle"
                  label-for="prime-e"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="prime-e"
                    v-model="UserToConfig.additional_prime"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="ModelCol"
                v-if="
                  UserToConfig.type != 'Admin GE' &&
                    UserToConfig.type != 'Responsable planning'
                "
              >
                <b-form-group
                  label="Fix"
                  label-for="prime-m"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="prime-m"
                    v-model="UserToConfig.fix"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Deducation"
                  label-for="deducation"
                  class="input-modal-champ"
                >
                  <b-form-input
                    id="deducation"
                    v-model="UserToConfig.deducation"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="ModelCol"
                v-if="
                  UserToConfig.type != 'Admin GE' &&
                    UserToConfig.type != 'Responsable planning'
                "
              >
                <b-form-group
                  label="Autre Prime (Nom)"
                  label-for="other_prime_name"
                  class=" input-modal-champ"
                >
                  <b-form-input
                    id="other_prime_name"
                    v-model="UserToConfig.other_prime_name"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Autre Prime (Montant)"
                  label-for="other_prime_value"
                  class="input-modal-champ"
                >
                  <b-form-input
                    id="other_prime_value"
                    v-model="UserToConfig.other_prime_value"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div
                class="ModelCol-min mb-2 mr-3"
                v-if="
                  UserToConfig.type == 'Admin GE' ||
                    UserToConfig.type == 'Responsable planning'
                "
              >
                <label id="label_source">Variable source</label>
                <b-form-input
                  class=""
                  id="variable_source"
                  v-model="UserToConfig.variable_source"
                ></b-form-input>
                <multiselect
                  v-model="UserToConfig.source"
                  placeholder="select source"
                  track-by="name"
                  label="name"
                  :options="getSources"
                  :searchable="true"
                  required
                  :multiple="true"
                  :close-on-select="false"
                  class="select-vue-component-style "
                  :showLabels="false"
                >
                  <span slot="noResult">Aucun source trouvé </span>
                  <span slot="noOptions">La liste est vide</span>
                </multiselect>
              </div>
              <div
                class="ModelCol-min mb-2 mr-3"
                v-if="
                  UserToConfig.type == 'Admin GE' ||
                    UserToConfig.type == 'Responsable planning'
                "
              >
                <label id="label_depot">Variable dépôt</label>
                <b-form-input
                  class="mr-2"
                  id="variable_depot"
                  v-model="UserToConfig.variable_depot"
                ></b-form-input>
                <multiselect
                  v-model="UserToConfig.depot"
                  placeholder="select depot"
                  track-by="name"
                  label="name"
                  :options="getDepots"
                  :searchable="true"
                  required
                  :multiple="true"
                  :close-on-select="false"
                  class="select-vue-component-style "
                  :showLabels="false"
                >
                  <span slot="noResult">Aucun depot trouvé </span>
                  <span slot="noOptions">La liste est vide</span>
                </multiselect>
              </div>

              <div class="actionModel">
                <div class="messageError">
                  <div v-if="getErrorUserPaies" class="error">
                    {{ getErrorUserPaies }}
                  </div>
                </div>
                <b-button type="submit" class="button-valide-style ">
                  <span>
                    Valider
                    <div v-if="getloagingUserPaies" class="loading ml-2">
                      <div class="spinner-border" role="status"></div></div
                  ></span>
                </b-button>
              </div>
            </form>
          </template>
        </Card>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      optionsTh: [
        { text: 'Par pourcentage', value: 'percentage' },
        { text: 'Par m2', value: 'per m2' },
        { text: 'Par dossier', value: 'Par dossier' }
      ],
      isLoading: false,
      UserToConfig: null,
      page: 1,
      perPage: 10,
      filterUser: { full_name: 'tous', id: null },
      filteryear: null,
      filterSemaine: null,
      filterType: null,
      paie: {
        year: null,
        employee: {
          id: null
        },
        semaine: null,
        type: null,
        variable_101: null,
        fix: null,
        IGD: null,
        additional_prime: null,
        variable_103: null,
        variable_102_ITI_placo: null,
        variable_102_ITI: null,
        variable_102_Standard: null,
        variable_vmc: null,
        variable_pac: null,
        prime_qualite: null,
        prime_objectif: null,
        prime_sav: null,
        other_prime_name: null,
        other_prime_value: null,
        deducation: null,
        variable_source: null,
        source: [],
        variable_depot: null,
        depot: [],
        TH_ITE: null,
        TH_ITI: null,
        TH_PAC_RO: null,
        TH_PAC_Balon_thermo: null,
        TH_RR: null,
        TH_interne: null,
        TH_externe: null,
        TH_condition: null,
        TH_value: null,
        facture_type: 'independant',
        interne_mpr_oui: 0,
        externe_mpr_oui: 0,
        interne_mpr_non: 0,
        externe_mpr_non: 0,
        previsite_mpr_oui: 0,
        vente_mpr_oui: 0,
        previsite_mpr_non: 0,
        vente_mpr_non: 0,
        surface_mpr_oui: 0,
        surface_iti_mpr_oui: 0,
        surface_mpr_non: 0,
        surface_iti_mpr_non: 0,
        TH_previsite: 0,
        TH_vente: 0
      },
      thType: false,
      isoType: true,
      th_type_to_config: false,
      showErrorUser: false,
      ListYear: [],
      ListWeek: [],
      ListOrganisme: [],
      fields: [
        { key: 'name', label: 'Nom' },
        { key: 'mail', label: 'E-mail' },
        { key: 'type', label: 'Type' },
        // { key: 'equipe', label: 'Equipe' },
        // { key: 'depot_employee', label: 'Depot' },
        { key: 'semaine', label: 'Semaine' },
        { key: 'fix', label: 'Fix' },

        { key: 'interne_mpr_oui', label: 'Interne(MPR-oui)' },
        { key: 'externe_mpr_oui', label: 'Externe(MPR-oui)' },
        { key: 'interne_mpr_non', label: 'Interne(MPR-non)' },
        { key: 'externe_mpr_non', label: 'Externe(MPR-non)' },
        { key: 'previsite_mpr_oui', label: 'Prévisite(MPR-oui)' },
        { key: 'vente_mpr_oui', label: 'Vente(MPR-oui)' },
        { key: 'previsite_mpr_non', label: 'Prévisite(MPR-non)' },
        { key: 'vente_mpr_non', label: 'Vente(MPR-non )' },
        { key: 'surface_mpr_oui', label: 'Surface(MPR-oui)' },
        { key: 'surface_iti_mpr_oui', label: 'Surface iti (MPR-oui)' },
        // { key: 'surface_iti_mpr_non', label: 'Surface iti (MPR-non)' },
        { key: 'TH_vente', label: 'Vente' },
        { key: 'TH_previsite', label: 'Previsite' },
        { key: 'variable_101', label: 'Variable  101' },
        { key: 'variable_102_Standard', label: 'Variable 102 Standard' },
        { key: 'variable_102_ITI', label: 'Variable 102 ITI + ' },
        { key: 'variable_102_ITI_placo', label: 'variable 102 ITI placo' },
        { key: 'variable_103', label: 'Variable  103' },
        { key: 'variable_vmc', label: 'Variable Vmc' },
        { key: 'variable_pac', label: 'Variable PAC' },
        { key: 'variable_source', label: 'Variable source' },
        { key: 'source', label: 'Sources' },
        { key: 'variable_depot', label: 'Variable dépôt' },
        { key: 'depot', label: 'Dépôts' },
        {
          key: 'IGD',
          label: 'IGD ',
          headerTitle: 'Indemnité Grand deplacement '
        },
        { key: 'prime_qualite', label: 'Prime Qualité' },
        { key: 'prime_objectif', label: 'Prime Objectif' },
        { key: 'prime_sav', label: 'Prime Sav' },
        { key: 'primeE', label: 'Prime exceptionnelle' },
        { key: 'other_prime_name', label: 'Autres Prime (Nom)' },
        { key: 'other_prime_value', label: 'Autres Prime (Montant)' },
        { key: 'deducation', label: 'Deducation' },
        { key: 'TH_condition', label: 'TH condition' },
        { key: 'TH_value', label: 'TH value' },
        { key: 'TH_externe', label: 'TH externe' },
        { key: 'TH_interne', label: 'TH interne' },
        { key: 'TH_RR', label: 'TH RR' },
        { key: 'TH_PAC_Balon_thermo', label: 'TH PAC Balon thermo' },
        { key: 'TH_PAC_RO', label: 'TH PAC RO' },
        { key: 'TH_ITI', label: 'TH ITI' },
        { key: 'TH_ITE', label: 'TH ITE' },
        { key: 'Actions', label: 'Actions' }
      ],
      options: [
        { text: 'Par type', value: 'type' },
        { text: 'Par utilisateur', value: 'user' }
      ],
      optionsFactureType: [
        { text: 'Independant', value: 'independant' },
        { text: 'Salarie', value: 'salarie' }
      ],
      ConfigType: 'type',
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapMutations(['SET_ERROR_USER_PAIE']),
    ...mapActions([
      'configUserPaie',
      'fetchUserFiltred',
      'fetchAllUserAndType',
      'donwloadSalary',
      'fetchAllRegieAndSource',
      'fetchUserConnectedWithType',
      'fetchAllDepotsWithTrashed'
    ]),
    async handleTypeChange(e) {
      this.isLoading = true;
      this.typeUserCondition(e);
      this.getAllType.map(item => {
        if (item.type == e) {
          this.paie.fix = item.fix;
          this.paie.variable_102_ITI = item.variable_102_ITI;
          this.paie.variable_101 = item.variable_101;
          this.paie.variable_102_ITI_placo = item.variable_102_ITI_placo;
          this.paie.variable_103 = item.variable_103;
          this.paie.IGD = item.IGD;
          this.paie.variable_102_Standard = item.variable_102_Standard;
          this.paie.variable_vmc = item.variable_vmc;
          this.paie.additional_prime = item.additional_prime;

          this.paie.variable_pac = item.variable_pac;
          this.paie.prime_qualite = item.prime_qualite;
          this.paie.prime_objectif = item.prime_objectif;
          this.paie.prime_sav = item.prime_sav;
          this.paie.other_prime_name = item.other_prime_name;
          this.paie.other_prime_value = item.other_prime_value;
          this.paie.deducation = item.deducation;
        }
      });
      this.paie.employee.id = null;
      const responce = await this.fetchUserConnectedWithType({
        type: e,
        year: this.paie.year,
        semaine: this.paie.semaine
      });
      if (responce) {
        this.isLoading = false;
      }
    },
    typeUserCondition(e) {
      if (e == 'Poseur' || e == 'Co poseur') {
        this.paie.TH_condition = 'per m2';
      } else {
        this.paie.TH_condition = 'percentage';
      }
    },
    resetModal() {
      this.paie.employee.id = null;
      this.paie.semaine = null;
      this.paie.year = null;
      this.paie.type = null;
      this.paie.variable_101 = null;
      this.paie.variable_102_ITI_placo = null;
      this.paie.variable_103 = null;
      this.paie.variable_102_ITI = null;
      this.paie.fix = null;
      this.paie.IGD = null;
      this.paie.additional_prime = null;
      this.paie.variable_102_Standard = null;
      this.paie.variable_vmc = null;
      this.paie.variable_pac = null;
      this.paie.prime_qualite = null;
      this.paie.prime_objectif = null;
      this.paie.prime_sav = null;
      this.paie.other_prime_name = null;
      this.paie.other_prime_value = null;
      this.paie.deducation = null;
      this.SET_ERROR_USER_PAIE(null);
      this.paie.variable_source = null;
      this.paie.source = [];
      this.paie.variable_depot = null;
      this.paie.depot = [];
      this.paie.TH_ITE = null;
      this.paie.TH_ITI = null;
      this.paie.TH_PAC_RO = null;
      this.paie.TH_PAC_Balon_thermo = null;
      this.paie.TH_RR = null;
      this.paie.TH_interne = null;
      this.paie.TH_externe = null;
      this.paie.TH_condition = null;
      this.paie.TH_value = null;
      this.paie.facture_type = 'independant';
      this.thType = false;
      this.ConfigType = 'type';
      this.th_type_to_config = false;
      this.paie.interne_mpr_oui = 0;
      this.paie.externe_mpr_oui = 0;
      this.paie.interne_mpr_non = 0;
      this.paie.externe_mpr_non = 0;
      this.paie.previsite_mpr_oui = 0;
      this.paie.vente_mpr_oui = 0;
      this.paie.previsite_mpr_non = 0;
      this.paie.vente_mpr_non = 0;
      this.paie.surface_mpr_oui = 0;
      this.paie.surface_iti_mpr_oui = 0;
      this.paie.surface_mpr_non = 0;
      // this.paie.surface_iti_mpr_non = 0;
      this.TH_previsite = 0;
      this.TH_vente = 0;
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      //this.fetchAllUserAndType();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      localStorage.setItem(
        'Gestion-Paies-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterUser: this.filterUser,
          filterType: this.filterType
        })
      );
      this.page = 1;
      sessionStorage.setItem('page-gestion-paies', this.page);
      this.fetchUserFiltred({
        id: this.filterUser,
        semaine: this.filterSemaine,
        year: this.filteryear,
        page: this.page,
        type: this.filterType,
        per_page: this.perPage
      });
    },
    handleUpdate(User) {
      this.UserToConfig = { ...User };
      if (this.UserToConfig.TH_condition != null) {
        this.th_type_to_config = true;
      } else {
        this.th_type_to_config = false;
      }
      if (this.UserToConfig.source) {
        this.UserToConfig.source.map(item => {
          const obj = this.getSources.filter(
            itemSource => itemSource.name == item.name
          );
          item.id = obj[0].id;
        });
      }
      if (this.UserToConfig.depot) {
        this.UserToConfig.depot.map(item => {
          const obj = this.getDepotsTrashed.filter(
            itemdepot => itemdepot.name == item.name
          );
          item.id = obj[0].id;
        });
      }

      this.$refs['ModelConfigUser'].show();
    },
    async ConfigurationUserNew() {
      this.UserToConfig.year = this.filteryear;
      const response = await this.configUserPaie({
        userPaie: this.UserToConfig,
        thType: this.th_type_to_config
      });
      if (response) {
        this.hideModal('ModelConfigUser');
      }
    },
    async ConfigurationPaies() {
      if (this.ConfigType == 'user') {
        if (this.paie.employee.id == null) {
          this.showErrorUser = true;
          return;
        }
      }
      if (this.ConfigType == 'type') {
        if (
          this.paie.type === 'Admin GE' ||
          this.paie.type === 'Responsable planning'
        ) {
          return;
        }
      }
      this.showErrorUser = false;
      const paietoCofig = {
        year: this.paie.year,
        employee: {
          id: null
        },
        semaine: this.paie.semaine,
        type: this.paie.type,
        variable_101: this.paie.variable_101,
        variable_102_ITI_placo: this.paie.variable_102_ITI_placo,
        variable_103: this.paie.variable_103,
        variable_102_ITI: this.paie.variable_102_ITI,
        fix: this.paie.fix,
        IGD: this.paie.IGD,
        additional_prime: this.paie.additional_prime,
        variable_102_Standard: this.paie.variable_102_Standard,
        variable_vmc: this.paie.variable_vmc,
        variable_pac: this.paie.variable_pac,
        prime_qualite: this.paie.prime_qualite,
        prime_objectif: this.paie.prime_objectif,
        prime_sav: this.paie.prime_sav,
        other_prime_name: this.paie.other_prime_name,
        other_prime_value: this.paie.other_prime_value,
        deducation: this.paie.deducation,
        interne_mpr_oui: this.paie.interne_mpr_oui,
        externe_mpr_oui: this.paie.externe_mpr_oui,
        interne_mpr_non: this.paie.interne_mpr_non,
        externe_mpr_non: this.paie.externe_mpr_non,
        previsite_mpr_oui: this.paie.previsite_mpr_oui,
        vente_mpr_oui: this.paie.vente_mpr_oui,
        previsite_mpr_non: this.paie.previsite_mpr_non,
        vente_mpr_non: this.paie.vente_mpr_non,
        surface_mpr_oui: this.paie.surface_mpr_oui,
        surface_iti_mpr_oui: this.paie.surface_iti_mpr_oui,
        surface_mpr_non: this.paie.surface_mpr_non,
        TH_vente: this.paie.TH_vente,
        TH_previsite: this.paie.TH_previsite
        // surface_iti_mpr_non: this.paie.surface_iti_mpr_non
      };
      if (this.paie.employee.id != null) {
        paietoCofig.employee.id = this.paie.employee.id.id;
        paietoCofig.source = this.paie.source;
        paietoCofig.variable_source = this.paie.variable_source;
        paietoCofig.depot = this.paie.depot;
        paietoCofig.variable_depot = this.paie.variable_depot;
      }
      if (this.thType == true) {
        paietoCofig.thType = this.thType;
        paietoCofig.TH_ITE = this.paie.TH_ITE;
        paietoCofig.TH_ITI = this.paie.TH_ITI;
        paietoCofig.TH_PAC_RO = this.paie.TH_PAC_RO;
        paietoCofig.TH_PAC_Balon_thermo = this.paie.TH_PAC_Balon_thermo;
        paietoCofig.TH_RR = this.paie.TH_RR;
        paietoCofig.TH_interne = this.paie.TH_interne;
        paietoCofig.TH_externe = this.paie.TH_externe;
        paietoCofig.TH_condition = this.paie.TH_condition;
        paietoCofig.TH_value = this.paie.TH_value;
      }
      paietoCofig.facture_type = this.paie.facture_type;

      const response = await this.configUserPaie({
        userPaie: paietoCofig
      });
      if (response) {
        this.hideModal('ConfigurationPaie');
      }
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-gestion-paies', this.page);
      this.fetchUserFiltred({
        id: this.filterUser,
        semaine: this.filterSemaine,
        year: this.filteryear,
        page: this.page,
        type: this.filterType,
        per_page: this.perPage
      });
    },
    handleChangeYear() {
      var Filters = JSON.parse(localStorage.getItem('Gestion-Paies-Filters'));
      Filters.filteryear = this.filteryear;
      localStorage.setItem('Gestion-Paies-Filters', JSON.stringify(Filters));
      this.page = 1;
      sessionStorage.setItem('page-gestion-paies', this.page);
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.fetchUserFiltred({
        id: this.filterUser,
        semaine: this.filterSemaine,
        year: this.filteryear,
        page: this.page,
        type: this.filterType,
        per_page: this.perPage
      });
    },
    async handleChangeYearModel() {
      this.isLoading = true;
      var totalWeek = moment({ year: this.paie.year }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      const responce = await this.fetchUserConnectedWithType({
        type: this.paie.type,
        year: this.paie.year,
        semaine: this.paie.semaine
      });
      this.isLoading = false;
      // if (!responce) {
      //   this.fetchAllUserAndType({ type: this.paie.type });
      // }
    },
    async handleChangeSemaineModel() {
      this.isLoading = true;
      const responce = await this.fetchUserConnectedWithType({
        type: this.paie.type,
        year: this.paie.year,
        semaine: this.paie.semaine
      });
      this.isLoading = false;
    },
    async exportFiles() {
      await this.donwloadSalary({
        semaine: this.filterSemaine,
        type: this.filterType,
        year: this.filteryear,
        employee_id: this.filterUser
      });
    },
    toggleSwitchTh() {
      if (
        this.th_type_to_config === true &&
        this.UserToConfig.TH_condition == null
      ) {
        if (
          this.UserToConfig.type == 'Poseur' ||
          this.UserToConfig.type == 'Co poseur'
        ) {
          this.UserToConfig.TH_condition = 'per m2';
        } else {
          this.UserToConfig.TH_condition = 'percentage';
        }
      }

      if (this.th_type_to_config === false) {
        this.UserToConfig.TH_condition = null;
      }
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-gestion-paies', this.page);

      this.fetchUserFiltred({
        id: this.filterUser,
        semaine: this.filterSemaine,
        year: this.filteryear,
        page: this.page,
        type: this.filterType,
        per_page: this.perPage
      });
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect'),
    SelectComponent: () => import('../SelectComponent.vue'),
    Card: () => import('../component/card')
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('Gestion-Paies-Filters')) {
      this.filterUser = JSON.parse(
        localStorage.getItem('Gestion-Paies-Filters')
      ).filterUser;
      this.filterSemaine = JSON.parse(
        localStorage.getItem('Gestion-Paies-Filters')
      ).filterSemaine;
      this.filteryear = JSON.parse(
        localStorage.getItem('Gestion-Paies-Filters')
      ).filteryear;
      this.filterType = JSON.parse(
        localStorage.getItem('Gestion-Paies-Filters')
      ).filterType;
    } else {
      this.filteryear = courantYear;
      this.filterSemaine = 's' + moment().isoWeek();
      localStorage.setItem(
        'Gestion-Paies-Filters',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterUser: this.filterUser,
          filterType: this.filterType
        })
      );
    }
    if (sessionStorage.getItem('page-gestion-paies')) {
      this.page = sessionStorage.getItem('page-gestion-paies');
    } else {
      sessionStorage.setItem('page-gestion-paies', this.page);
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    this.SET_ERROR_USER_PAIE(null);
    this.fetchUserFiltred({
      id: this.filterUser,
      semaine: this.filterSemaine,
      year: this.filteryear,
      page: this.page,
      type: this.filterType,
      per_page: this.perPage
    });
    this.fetchAllUserAndType();
    this.fetchAllRegieAndSource();
    this.fetchAllDepotsWithTrashed();
  },

  computed: {
    ...mapGetters([
      'getTotalRowUser',
      'getloagingUserPaies',
      'getErrorUserPaies',
      'getAllUser',
      'getAllEmployee',
      'getAllType',
      'getChargementPaie',
      'getDepots',
      'getSources',
      'getErronInFetchActiveUser',
      'getDepotsTrashed',
      'getListEmployeeByType'
    ]),
    ComputedListWeek() {
      return [{ value: null, text: 'tous' }, ...this.ListWeek];
    },
    ComputedgetAllEmployee() {
      return [{ id: null, full_name: 'tous' }, ...this.getAllEmployee];
    },
    getAllEmployeeByType() {
      return this.getListEmployeeByType;
    },
    // ComputedgetAllType() {
    //   const type = this.getAllType.map(item => ({
    //     text: item.type,
    //     value: item.type
    //   }));
    //   return [{ text: 'tous', value: null }, ...type];
    // },
    ComputedgetType() {
      const type = this.getAllType.map(item => {
        if (item.type == 'Pre visiteur') {
          return {
            value: item.type,
            type: item.type,
            text: 'Pre visiteur_Commercial terrain',
            id: item.id
          };
        } else {
          return {
            text: item.type,
            type: item.type,
            value: item.type,
            id: item.id
          };
        }
      });
      const types = type.filter(item => item.value != 'Commercial terrain');
      return types;
    },

    computedgetuserConfig() {
      if (
        this.filterSemaine === null &&
        this.filterType === null &&
        this.filterUser !== null &&
        this.filterUser.id &&
        this.filterUser.id !== null
      ) {
        return this.getAllUser.filter(
          row => row.employee.id == this.filterUser.id
        );
      } else if (
        this.filterSemaine === null &&
        this.filterType !== null &&
        this.filterUser !== null &&
        this.filterUser.id &&
        this.filterUser.id !== null
      ) {
        return this.getAllUser.filter(
          row =>
            row.employee.id == this.filterUser.id && row.type == this.filterType
        );
      } else if (
        this.filterSemaine !== null &&
        this.filterType === null &&
        (this.filterUser === null ||
          (this.filterUser.id && this.filterUser.id === null))
      ) {
        return this.getAllUser.filter(row => row.semaine == this.filterSemaine);
      } else if (
        this.filterSemaine !== null &&
        this.filterType !== null &&
        (this.filterUser === null ||
          (this.filterUser.id && this.filterUser.id === null))
      ) {
        return this.getAllUser.filter(
          row =>
            row.type == this.filterType && row.semaine == this.filterSemaine
        );
      } else if (
        this.filterSemaine !== null &&
        this.filterType === null &&
        this.filterUser !== null &&
        this.filterUser.id &&
        this.filterUser.id !== null
      ) {
        return this.getAllUser.filter(
          row =>
            row.employee.id == this.filterUser.id &&
            row.semaine == this.filterSemaine
        );
      } else if (
        this.filterSemaine === null &&
        this.filterType !== null &&
        this.filterUser.id &&
        (this.filterUser === null ||
          (this.filterUser.id && this.filterUser.id === null))
      ) {
        return this.getAllUser.filter(row => row.type == this.filterType);
      } else if (
        this.filterSemaine !== null &&
        this.filterType !== null &&
        this.filterUser !== null &&
        this.filterUser.id &&
        this.filterUser.id !== null
      ) {
        return this.getAllUser.filter(
          row =>
            row.semaine === this.filterSemaine &&
            row.employee.id === this.filterUser.id &&
            row.type == this.filterType
        );
      } else {
        return this.getAllUser;
      }
    },
    computedOptionsTh() {
      return [
        { text: 'Par pourcentage', value: 'percentage', disabled: true },
        { text: 'Par m2', value: 'per m2', disabled: true },
        { text: 'Par dossier', value: 'Par dossier', disabled: true }
      ];
    },
    computedThSedentaire() {
      return function(data) {
        if (
          data &&
          data.type &&
          data.type == 'Commercial sedentaire' &&
          data.TH_condition != null &&
          data.TH_condition != false
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedSedentaire() {
      return function(data) {
        if (data && data.type && data.type == 'Commercial sedentaire') {
          return true;
        } else {
          return false;
        }
      };
    },
    computedPrevisiteur() {
      return function(data) {
        if (data && data.type && data.type == 'Pre visiteur') {
          return true;
        } else {
          return false;
        }
      };
    },
    computedPoseurCoposeur() {
      return function(data) {
        if (
          data &&
          data.type &&
          (data.type == 'Poseur' || data.type == 'Co poseur')
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedAutre() {
      return function(data) {
        if (
          data &&
          data.type &&
          data.type != 'Poseur' &&
          data.type != 'Co poseur' &&
          data.type != 'Pre visiteur' &&
          data.type != 'Commercial sedentaire' &&
          data.type != 'Admin GE' &&
          data.type != 'Responsable planning' &&
          data.type != null
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedThPoseurIndependant() {
      return function(data) {
        if (
          data &&
          data.type &&
          (data.type == 'Poseur' || data.type == 'Co poseur') &&
          data.TH_condition != null &&
          data.TH_condition != false &&
          data.facture_type == 'independant'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedThPoseurSalarie() {
      return function(data) {
        if (
          data &&
          data.type &&
          (data.type == 'Poseur' || data.type == 'Co poseur') &&
          data.TH_condition != null &&
          data.TH_condition != false &&
          data.facture_type == 'salarie'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedThRest() {
      return function(data) {
        if (
          data &&
          data.type &&
          data.type != 'Poseur' &&
          data.type != 'Co poseur' &&
          data.type != 'Commercial sedentaire' &&
          data.type != 'Pre visiteur' &&
          data.TH_condition != null &&
          data.TH_condition != false
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedThPrevisiteur() {
      return function(data) {
        if (
          data &&
          data.type &&
          data.type == 'Pre visiteur' &&
          data.TH_condition != null &&
          data.TH_condition != false
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedFiled() {
      if (this.filterType == null) {
        return this.fields;
      }
      if (this.filterType == 'Co poseur' || this.filterType == 'Poseur') {
        return this.fields.filter(
          item =>
            item.key != 'variable_101' &&
            item.key != 'variable_102_Standard' &&
            item.key != 'variable_102_ITI' &&
            item.key != 'variable_102_ITI_placo' &&
            item.key != 'variable_103' &&
            item.key != 'interne_mpr_oui' &&
            item.key != 'externe_mpr_oui' &&
            item.key != 'interne_mpr_non' &&
            item.key != 'externe_mpr_non' &&
            item.key != 'previsite_mpr_oui' &&
            item.key != 'vente_mpr_oui' &&
            item.key != 'previsite_mpr_non' &&
            item.key != 'vente_mpr_non' &&
            item.key != 'TH_externe' &&
            item.key != 'TH_interne' &&
            item.key != 'TH_vente' &&
            item.key != 'TH_previsite'
        );
      }
      if (
        this.filterType == 'Pre visiteur' ||
        this.filterType == 'Commercial terrain'
      ) {
        return this.fields.filter(
          item =>
            item.key != 'variable_101' &&
            item.key != 'variable_102_Standard' &&
            item.key != 'variable_102_ITI' &&
            item.key != 'variable_102_ITI_placo' &&
            item.key != 'variable_103' &&
            item.key != 'interne_mpr_oui' &&
            item.key != 'externe_mpr_oui' &&
            item.key != 'interne_mpr_non' &&
            item.key != 'externe_mpr_non' &&
            item.key != 'surface_mpr_oui' &&
            item.key != 'surface_iti_mpr_oui' &&
            item.key != 'TH_externe' &&
            item.key != 'TH_interne' &&
            item.key != 'TH_RR' &&
            item.key != 'TH_PAC_Balon_thermo' &&
            item.key != 'TH_PAC_RO' &&
            item.key != 'TH_ITI' &&
            item.key != 'TH_ITE'
        );
      }
      if (this.filterType == 'Commercial sedentaire') {
        return this.fields.filter(
          item =>
            item.key != 'variable_101' &&
            item.key != 'variable_102_Standard' &&
            item.key != 'variable_102_ITI' &&
            item.key != 'variable_102_ITI_placo' &&
            item.key != 'variable_103' &&
            item.key != 'surface_mpr_oui' &&
            item.key != 'surface_iti_mpr_oui' &&
            // item.key != 'surface_iti_mpr_non' &&
            item.key != 'previsite_mpr_oui' &&
            item.key != 'vente_mpr_oui' &&
            item.key != 'previsite_mpr_non' &&
            item.key != 'vente_mpr_non' &&
            item.key != 'TH_RR' &&
            item.key != 'TH_PAC_Balon_thermo' &&
            item.key != 'TH_PAC_RO' &&
            item.key != 'TH_ITI' &&
            item.key != 'TH_ITE' &&
            item.key != 'TH_vente' &&
            item.key != 'TH_previsite'
        );
      }
      return this.fields;
    }
  }
};
</script>
<style scoped lang="scss">
.contentCnfigP {
  padding: 10px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .chargement {
    font-size: 8px;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}
.label-gestion-paie {
  font-weight: 700;
  color: black;
  font-size: 12px;
}
.align-left-div {
  text-align-last: left;
  width: 50%;
}
.bar-th-style {
  text-align-last: justify;
  display: flex;

  .champ-bar-th {
    margin-left: 10px;
    text-align-last: start;
    width: 100%;
  }
  .ligne-th {
    border-style: dotted;
    border-bottom: 0px;
    border-color: #e8e7ea;
    margin-top: 9px;
  }
}

.checkbox-style-type {
  margin-top: 10px;
  text-align: justify;
  .check-th {
    width: 43%;
  }
  .check-th-active {
    margin: auto;
  }
  .condition-th-style {
    display: flex;
    width: 78%;
  }
}
.table-gestion-paies-style {
  max-height: calc(100vh - 247px) !important;
  height: calc(100vh - 247px) !important;
  margin-bottom: 0px;
}
</style>
<style lang="scss">
.table-gestion-paies-style {
  td,
  th {
    padding: 5px !important;
    min-width: 230px;
  }
}
</style>
